
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import ContentTitle from '@/components/ContentTitle.vue';
import Pictures from "./Pictures.vue";
import AddDiscount from "./addDiscount.vue";
import UE from "@/components/RichTextEditor/ueditor.vue";
import { ElForm } from 'element-ui/types/form';

@Component({
    name: 'AddSaas',
    components: {
        ContentTitle,
        Pictures,
        AddDiscount,
        UE,
    },
})
export default class AddSaas extends Vue {
    @Prop(Number) private id!: number;
    @Prop({
        type: String,
        default: 'add',
    }) private pageType!: string;   // add; edit; info;
    private contentTitle: string = '发布Saas';
    private baseUrl: string = process.env.NODE_ENV === 'development' ? '/apiProxy' : '';
    private isView: boolean = false;
    private initUE: boolean = false;
    private formData: any = {
        name: '',
        serialNumber: '',
        industryTypes: [],
        icon: '',
        pictures: [],
        priceItems: [{
            type: 1,
            price: '',
            unit: '',
            existFlag: true,
        }, {
            type: 4,
            price: '',
            unit: '',
            existFlag: true,
        }, {
            type: 2,
            price: '',
            existFlag: false,
        }, {
            type: 3,
            price: '',
            existFlag: false,
        }],
        externalLink: '',
        description: '',
        introduction: '',
        discountItems: [],
    };
    private rules: any = {
        name: [
            { required: true, message: '请输入商品名称', trigger: 'change' },
        ],
        serialNumber: [
            { required: true, message: '请输入商品编码', trigger: 'change' },
        ],
        // industryTypes: [
        //     { required: true, message: '请选择行业类型', trigger: 'change' },
        // ],
        priceItems: [
            { validator: (rule: any, value: any, callback: any) => {
                const existFlag = value.findIndex((item: any) => item.existFlag);
                if (existFlag < 0) {
                    callback('至少选择一种售价规则');
                } else {
                    callback();
                }
            }, trigger: 'change' },
        ],
        icon: [
            { required: true, message: '请上传商品ICON', trigger: 'change' },
        ],
        pictures: [
            { required: true, message: '请上传轮播图', trigger: 'change' },
        ],
        description: [
            { required: true, message: '请输入详情', trigger: 'change' },
        ],
        introduction: [
            { required: true, message: '请输入商品简介', trigger: 'change' },
        ],
        // mobile: [
        //     { required: true, message: '请输入手机号', trigger: 'change' },
        //     { validator: this.validateMobile, trigger: 'change' },
        // ],
    };
    private discountRules: any = {
        monthlyList: [],
        yearlyList: [],
    };    // 折扣规则
    private industryTypes: any = [];    // 行业类型
    // 添加折扣规则
    private discountVisible: boolean = false;
    private discountInfo: any = {};
    private discountOptions: any = [];
    // UE
    private ueditorConfig: any = {
        autoHeightEnabled: false,
        autoFloatEnabled: false,
        initialFrameHeight: 300,
        initialFrameWidth: null, // 关闭字数统计
        wordCount: false, // 关闭elementPath
        elementPathEnabled: false,
        enableAutoSave: false,
        toolbars: [[
            'fullscreen', 'source', '|', 'undo', 'redo', '|',
            'bold', 'italic', 'underline', 'fontborder', 'strikethrough', 'superscript', 'subscript', 'removeformat', 'formatmatch', 'autotypeset', 'blockquote', 'pasteplain', '|', 'forecolor', 'backcolor', 'insertorderedlist', 'insertunorderedlist', 'selectall', 'cleardoc', '|',
            'rowspacingtop', 'rowspacingbottom', 'lineheight', '|',
            'customstyle', 'paragraph', 'fontfamily', 'fontsize', '|',
            'directionalityltr', 'directionalityrtl', 'indent', '|',
            'justifyleft', 'justifycenter', 'justifyright', 'justifyjustify', '|', 'touppercase', 'tolowercase', '|',
            'link', 'unlink', 'anchor', '|', 'imagenone', 'imageleft', 'imageright', 'imagecenter', '|',
            'simpleupload', 'insertimage', 'emotion', 'scrawl', 'insertvideo', 'attachment', 'map', 'insertframe', 'insertcode', 'webapp', 'pagebreak', 'template', 'background', '|',
            'horizontal', 'date', 'time', 'spechars', 'snapscreen', 'wordimage', '|',
            'inserttable', 'deletetable', 'insertparagraphbeforetable', 'insertrow', 'deleterow', 'insertcol', 'deletecol', 'mergecells', 'mergeright', 'mergedown', 'splittocells', 'splittorows', 'splittocols', 'charts', '|',
            'print', 'preview', 'searchreplace', 'help', 'drafts',
        ]],
    };
    private formRef: any = null;
    private monthlyList(discountItems: any) {
        discountItems = discountItems || [];
        return discountItems.filter((item: any) => item.number < 12);
    }
    private yearlyList(discountItems: any) {
        discountItems = discountItems || [];
        return discountItems.filter((item: any) => item.number >= 12);
    }
    private validatePrice(rule: any, value: any, callback: any, priceItem: any) {
        const moneyVlid = /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/;
        if (!priceItem.existFlag) {
            callback();
        } else if (value === null || value === '') {
            callback(new Error('价格不能为空'));
        } else if (value !== null && value !== '' && value !== '0' && !moneyVlid.test(value)) {
            callback(new Error('价格格式不正确'));
        } else if ((priceItem.type === 2 || priceItem.type === 3) && (parseInt(value, 10) <= 0)) {
            callback(new Error('当前售价规则价格不能为0元'));
        } else  {
            callback();
        }
    }
    private validateLicenses(rule: any, value: any, callback: any, priceItem: any, field: string) {
        const numVlid = /(^[1-9]\d*$)/;
        if (!priceItem.existFlag) {
            callback();
        } else if (value !== null && value !== '' && !numVlid.test(value)) {
            callback(new Error('数量格式不正确'));
        } else if (field === 'minimumLicenses' && (parseInt(value, 10)
            < parseInt(priceItem.complimentaryLicenses, 10))) {
            callback(new Error('起购数不能少于授权数'));
        } else  {
            callback();
        }
    }
    private formatterDiscount(num: number) {
        let timeUnit = num + '个月';
        if (num >= 12) {
            timeUnit = (num / 12) + '年';
        }
        return timeUnit;
    }
    // 是否永久
    private changeExistFlag(val: boolean, priceIndex: number) {
        if (this.formData.priceItems[priceIndex].type === 1) {
            const priceItems = this.formData.priceItems;
            const index = priceItems.findIndex((item: any) => item.type === 4);
            this.formData.priceItems[index].existFlag = val;
            this.clearValidate(['priceItems'
                , `priceItems.${priceIndex}.price`
                , `priceItems.${priceIndex}.unit`
                , `priceItems.${index}.price`
                , `priceItems.${index}.unit`]);
        } else {
            this.clearValidate(['priceItems', `priceItems.${priceIndex}.price`]);
        }
    }
    // 清除指定表单校验
    private clearValidate(arr: any = []) {
        const formRef = this.$refs.formRef as ElForm;
        formRef.clearValidate(arr);
    }
    // 给父组件传值
    @Emit('closeHandle')
    private closeTodo(num: number): number {
        return num;
    }
    private answerContent(value: any) {
        this.formData.description = value;
        this.clearValidate(['description']);
    }
    private created() {
        this.getIndustryTypes();
        this.getDiscountRules();
        if (this.id) {
            this.contentTitle = "修改Saas";
            this.getGoodsInfo();
        } else {
            this.initUE = true;
        }
        if (this.pageType === 'info') {
            this.contentTitle = "查看Saas";
        }
        this.$nextTick(() => {
            this.formRef = this.$refs.formRef;
        });
    }
    // 获取商品详情
    private getGoodsInfo() {
        this.$httpService.getData({}, `/apiProxy/api/frontend/mall/goods/saas/${this.id}`)
        .then((res: any) => {
            this.formData = res;
            this.initUE = true;
        });
    }
    // 获取行业类型
    private getIndustryTypes() {
        this.$httpService.getData({}, `/apiProxy/api/frontend/config/industry-types`)
        .then((rep: any) => {
            this.industryTypes = rep;
        });
    }
    // 获取支付折扣规则
    private getDiscountRules() {
        this.$httpService.getData({}, `/apiProxy/api/frontend/config/pay-discount-rules`)
        .then((rep: any) => {
            this.discountRules = rep;
        });
    }
    private filterPriceItem(data: any) {
        return data.map((item: any) => {
            if (!item.existFlag) {
                return {
                    ...item,
                    price: '',  // 价格
                };
            } else {
                return item;
            }
        });
    }
    private handleSave(status?: number) {
        const formRef: any = this.$refs.formRef;
        formRef.validate((valid: boolean) => {
            if (valid) {
                const formData: any = Object.assign({}, {
                    ...this.formData,
                    priceItems: this.filterPriceItem(this.formData.priceItems),
                });
                if (status === 0 || status === 1) {
                    formData.status = status;
                }
                // console.log(formData);
                if (this.id) {
                    this.handleUpdate(formData);
                } else {
                    this.handleAdd(formData);
                }
            } else {
                this.$message.error('请检查表单是否完善！');
            }
        });
    }
    // 添加
    private handleAdd(formData: any) {
        this.$httpService.putData(formData, '/apiProxy/api/frontend/mall/goods/saas')
        .then((res: any) => {
            this.$message.success('提交成功！');
            this.closeTodo(3);
        }).catch((err: any) => {
            // if (err.code === 1004013001) {
            //     this.projectNameError = '此项目名称已使用过，请勿重复申请！';
            // }
        });
    }
    // 修改
    private handleUpdate(formData: any) {
        this.$httpService.postData(formData, `/apiProxy/api/frontend/mall/goods/saas/${this.id}`)
        .then((res: any) => {
            this.$message.success('修改成功！');
            this.closeTodo(3);
        }).catch((err: any) => {
            // if (err.code === 1004013001) {
            //     this.projectNameError = '此项目名称已使用过，请勿重复申请！';
            // }
        });
    }
    private propGoBlack() {
        this.closeTodo(1);
    }
    //  下载附件
    private async downloadFile(fileUrl: any, fileName: any) {
        this.$message.success('文件下载中，请稍等...');
        const response = await fetch(this.baseUrl  + fileUrl);
        const blob = await response.blob();
        this.triggerDownload(URL.createObjectURL(blob), fileName);
    }
    private triggerDownload(blob: any, fileName: string) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = blob;
        a.download = fileName;
        a.click();
        document.body.removeChild(a);
    }
    // 添加应用icon、规格icon
    private beforeUploadIcon(file: any) {
        const fileType = file.type;
        const fileName = file.name;
        // 根据后缀判断文件类型
        let fileSuffix = "";
        try {
            const fileArr = fileName.split(".");
            fileSuffix = fileArr[fileArr.length - 1];
        } catch (err) {
            fileSuffix = "";
        }
        console.log('fileType', fileType);
        const isCorrect = (
            fileType === 'image/png'
            || fileType === 'image/jpg'
            || fileType === 'image/jpeg'
        );
        const isLt2M = file.size / 1024 / 1024 < 1;
        if (!isLt2M) {
            this.$message.error('上传文件大小不能超过 1MB!');
            return false;
        }
        if (!isCorrect) {
            this.$message.error('上传图片格式错误!');
            return false;
        }
    }
    private uploadIcon(data: any) {
        const param = new FormData();
        param.append('file', data.file);
        this.$httpService.postData(param, '/apiProxy/api/frontend/file/upload_image', {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        })
        .then((res: any) => {
            this.formData.icon = res.path;
            this.clearValidate(['icon']);
            // this.fileName = data.file.name;
        }).catch(() => {
            // this.file = null;
            // this.fileList = [];
        });
    }
    // 售价类型下拉框disabled
    private disabledOption(type: any, val: any) {
        if (type === val) {
            return false;
        }
        return this.formData.priceItems.findIndex((item: any) => item.type === val) >= 0;
    }
    // 移除售价规则
    private removePriceItem(index: number, type: number) {
        if (type === 1) {
            const type4Index = this.formData.priceItems.findIndex((item: any) => item.type === 4);   // 永久（伙伴）
            if (type4Index >= 0) {
                this.formData.priceItems.splice(type4Index, 1);
            }
        }
        this.formData.priceItems.splice(index, 1);
    }
    // 增加售价规则
    private addPriceItem() {
        let index = 0;
        const priceItems = this.formData.priceItems;
        const type1Index = priceItems.findIndex((item: any) => item.type === 1);   // 永久使用
        const type2Index = priceItems.findIndex((item: any) => item.type === 2);   // 按月
        const type3Index = priceItems.findIndex((item: any) => item.type === 3);   // 按年（市场）

        if (type1Index >= 0 && (priceItems[type1Index] !== ""
            && (priceItems[type1Index].price === 0 || priceItems[type1Index].price === '0'))) {
            this.$message.warning('当前产品价格已被设置为0元，不能支持其他售价，如果需要修改， 请调整当前已有的售价规则');
            return;
        }
        if (type1Index < 0) {
            index = 1;
        } else if (type2Index < 0) {
            index = 2;
        } else if (type3Index < 0) {
            index = 3;
        } else {
            this.$message.warning('无法继续添加销售规则');
            return;
        }

        this.formData.priceItems.push({
            type: index,
            price: '',
            unit: '',
            existFlag: true,
        });
        if (type1Index < 0) {
            this.formData.priceItems.push({
                type: 4,
                price: '',
                unit: '',
                existFlag: true,
            });
        }
    }
    // 折扣规则
    private openDiscount(row: any, field: string, discountItems: any) {
        const formRef: any = this.$refs.formRef;
        formRef.validateField(field, (valid: boolean) => {
            if (!valid) {
                // if (!row.price.trim()) {
                //     this.$message.warning('价格不能为空');
                //     return;
                // }
                if (row.type === 2) {
                    this.discountOptions = this.discountRules.monthlyList.filter((item: any) => {
                        return this.monthlyList(discountItems)
                            .findIndex((col: any) => col.number === item.number) < 0;
                    });
                } else if (row.type === 3) {
                    this.discountOptions = this.discountRules.yearlyList.filter((item: any) => {
                        return this.yearlyList(discountItems)
                            .findIndex((col: any) => col.number === item.number) < 0;
                    });
                }
                this.discountInfo = Object.assign({}, row);
                this.discountVisible = true;
            }
        });
    }
    private closeDiscount(data: any) {
        const {num, obj} = data;
        if (num === 3) {
            this.formData.discountItems.push(obj);
        }
        this.discountVisible = false;
    }
    private handleRemoveDiscount(tag: any) {
        this.formData.discountItems = this.formData.discountItems.filter((item: any) => {
            return item.number !== tag.number;
        });
    }
}
