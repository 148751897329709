
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    name: 'Pictures',
})
export default class Pictures extends Vue {
    @Prop({
        type: Array,
        default: () => {
            return [];
        },
    }) private pictures!: any; // 轮播图
    @Prop(String) private pageType!: string;
    @Prop(Object) private formRef!: any;
    private baseUrl: string = process.env.NODE_ENV === 'development' ? '/apiProxy' : '';
    // 清除指定表单校验
    private clearValidate(arr: any = []) {
        this.formRef.clearValidate(arr);
    }
    // 添加轮播图
    private beforeUploadPicture(file: any) {
        const fileType = file.type;
        const fileName = file.name;
        // 根据后缀判断文件类型
        let fileSuffix = "";
        try {
            const fileArr = fileName.split(".");
            fileSuffix = fileArr[fileArr.length - 1];
        } catch (err) {
            fileSuffix = "";
        }
        // console.log('fileType', fileType);
        const isCorrect = (
            fileType === 'image/png'
            || fileType === 'image/jpg'
            || fileType === 'image/jpeg'
        );
        const isLt2M = file.size / 1024 / 1024 < 1;
        if (!isLt2M) {
            this.$message.error('上传文件大小不能超过 1MB!');
            return false;
        }
        if (!isCorrect) {
            this.$message.error('上传图片格式错误!');
            return false;
        }
    }
    private uploadPicture(data: any) {
        const param = new FormData();
        param.append('file', data.file);
        this.$httpService.postData(param, '/apiProxy/api/frontend/file/upload_image', {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        })
        .then((res: any) => {
            if (this.pictures.findIndex((item: any) => item === res.path) >= 0) {
                this.$message.error('该图片已上传!');
            } else {
                this.pictures.push(res.path);
                this.clearValidate(['pictures']);
            }
        });
    }
    private removePicture(val: any) {
        const index = this.pictures.findIndex((img: any) => img === val);
        if (index >= 0) {
            this.pictures.splice(index, 1);
        }
    }
}
