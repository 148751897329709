
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';

// 截取2位小数
function fixNumber(val = 0, numLength = 2) {
    let length: number | string = val.toString();
    let num = '0.';
    if (length.indexOf('.') === -1) {
        return val;
    }
    length = length.split('.')[1].length < numLength ? numLength : length.split('.')[1].length;
    for (let i = 0; i < length; i++) {
        num += '0';
    }
    num += '1';
    return Math.floor((Number(val) + Number(num)) * Math.pow(10, numLength)) / Math.pow(10, numLength);
}

const validateMoney = (rule: any, value: any, callback: any) => {
    const moneyVlid = /^((0\.[1-9]{1})|(([1-9]{1})(\.\d{1})?))$/;
    if (value === null || value === '') {
        callback(new Error('请输入折扣数额'));
    } else if (value !== null && value !== '' && !moneyVlid.test(value)) {
        callback(new Error('折扣格式不正确,请输入0.1~9.9范围的数值'));
    } else {
        callback();
    }
};
@Component({
    name: "AddDiscount",
})
export default class AddDiscount extends Vue {
    @Prop({
        type: Boolean,
        default: false,
    }) private visible!: boolean; // 接收父组件传过来的值
    @Prop({
        type: Object,
        default: {},
    }) private detailInfo!: any; // 接收父组件传过来的值
    @Prop({
        type: Array,
        default: [],
    }) private discountOptions: any; // 接收父组件传过来的值
    private baseUrlIframe = process.env.VUE_APP_URL;
    private formData: any = {
        number: null,
        discount: '',
    };
    private money: any = "0.00";
    private rules: any = {
        times: [
            { required: true, message: '请选择到期时间', trigger: 'change' },
        ],
        discount: [
            { validator: validateMoney },
        ],
    };
    private dialogTitle = '新增折扣规则';
    @Watch('visible') // 监听父组件传过来的值
    private visibleChange(value: boolean) {
        if (value) {
            // 打开弹窗
            this.money = "0.00";
            const formRef: any = this.$refs.formRef;
            if (formRef) {
                formRef.resetFields();
            }
            this.$nextTick(() => {
                this.formData = {
                    number: this.discountOptions[0].number,
                    discount: '',
                };
            });
        }
    }
    // 给父组件传值
    @Emit('handleClose')
    private closeTodo(num: number): any {
        const obj = Object.assign({}, this.formData);
        return ({num, obj});
    }
    private discountChange() {
        const price = this.detailInfo.price;
        const discount = this.formData.discount;
        let times = this.formData.number;
        const reg = /^((0\.[1-9]{1})|(([1-9]{1})(\.\d{1})?))$/;
        if (discount.trim() && reg.test(discount)) {
            if (times >= 12) {
                times = times / 12;
            }
            const discountPrice = fixNumber(fixNumber(price * discount / 10) * times);
            this.money = discountPrice.toFixed(2);
        }
    }
    // 提交申请
    private handleSubmit() {
        const formRef: any = this.$refs.formRef;
        formRef.validate((valid: boolean) => {
            if (valid) {
                this.submitRequest();
            }
        });
    }
    private submitRequest() {
        this.closeTodo(3);
    }
    private cancelClose() {
        this.closeTodo(1);
    }
}
