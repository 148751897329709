
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';

@Component({
    name: 'AddCustomerService',
})
export default class AddCustomerService extends Vue {
    @Prop({
        type: Boolean,
        default: false,
    }) private showDialog!: boolean; // 接收父组件传过来的值
    @Prop({
        type: Object,
        default: () => {
            return {};
        },
    }) private detailInfo!: any;
    private formData: any = {
        customServiceId: null,
    };
    private rules: any = {
        customServiceId: [
            { required: true, message: '请选择客服', trigger: 'change' },
        ],
    };
    private serviceList: any = [];    // 客服列表

    @Watch('showDialog') // 监听父组件传过来的值
    private showDialogFun(value: boolean) {
        if (value) {
            this.getServiceList();
            if (this.detailInfo.customServiceId) {
                this.formData.customServiceId = this.detailInfo.customServiceId;
            }
        }
    }

    // 给父组件传值
    @Emit('handleClose')
    private handleCloseTodo(num: number): number {
        const formRef: any = this.$refs.formRef;
        formRef.resetFields();
        return num;
    }
    // 获取客服列表
    private getServiceList() {
        this.$httpService.getData({}, `/apiProxy/api/frontend/goods/custom-services`)
        .then((rep: any) => {
            this.serviceList = rep;
        });
    }
    // 提交
    private submit() {
        const formRef: any = this.$refs.formRef;
        formRef.validate((valid: boolean) => {
            if (valid) {
                this.$httpService.putData(this.formData, `/apiProxy/api/frontend/goods/${this.detailInfo.id}/custom-services`)
                    .then((res: any) => {
                        this.$message({
                            message: '添加成功!',
                            type: 'success',
                        });
                        this.handleCloseTodo(3);
                    });
            }
        });
    }
    //  取消按钮
    private closeDialog(num: number) {
        this.handleCloseTodo(num); // 给父组件传值：2：取消；3：导入成功
    }
    // 右上角的关闭按钮
    private beforeClose() {
        this.handleCloseTodo(0); // 给父组件传值
    }
}
