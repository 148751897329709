
import '@/assets/css/iframeStyle.scss';
import { Component, Vue, Watch } from 'vue-property-decorator';
import ContentTitle from "@/components/ContentTitle.vue";
import SearchHeader from "@/components/BaseTable/SearchHeader.vue";
import BaseTable from "@/components/BaseTable/index.vue";
import AddSaas from "./components/AddSaas.vue";
import AddCustomerService from './components/AddCustomerService.vue';

const validateMoney = (rule: any, value: any, callback: any) => {
    const moneyVlid = /(^[1-9]([0-9]+)?(\.[0-9]+)?$)|(^(0){1}$)|(^[0-9]\.[0-9]+$)/;
    if (value !== null && value !== '' && value !== '0' && !moneyVlid.test(value)) {
        callback(new Error('请输入金额'));
    } else {
        callback();
    }
};
@Component({
    name: "SaasProduct",
    components: {
        ContentTitle,
        SearchHeader,
        BaseTable,
        AddSaas,
        AddCustomerService,
    },
})
export default class SaasProduct extends Vue {
    private contentTitle: string = "Saas产品";
    private baseUrlIfram = process.env.VUE_APP_URL;
    private activePage = 'SAASLIST';  // SAASLIST:列表页；2:签约页；SAASADD:添加SAAS；4:添加服务；5:添加组件
    private saasId: any = null;
    private currentRow: any = {};
    private addServiceFlag: boolean = false;
    private addPageType: string = 'add';
    private total = 0;
    private currentPage = 1;
    private showHideFlag = false;
    private searchParam = {
        pageSize: 10,
        pageNo: 1,
        id: null,
        name: null,
        startDate: '',
        endDate: '',
        status: '',
        startPrice: null,
        endPrice: null,
        clicks: null,
        startSales: '',
        endSales: '',
        order: '1',
    };
    private startOptions: any = {
        disabledDate: (val: any) => {
            return false;
        },
    };
    private endOptions: any = {
        disabledDate: (val: any) => {
            return false;
        },
    };
    private rules = {
        startPrice: [{ validator: validateMoney }],
        endPrice: [{ validator: validateMoney }],
    };
    private tableHeader = [
        {
            width: "80",
            prop: "id",
            label: "商品编号",
        },
        {
            width: "80",
            prop: "id",
            label: "",
            render: (h: any, scope: any) => {
                return h('el-image', {
                    style: {
                        width: '60px',
                        height: '60px',
                    },
                    props: {
                        src: this.baseUrlIfram + scope.row.icon,
                        previewSrcList: [this.baseUrlIfram + scope.row.icon],
                    },
                });
            },
        },
        {
            width: "260",
            prop: "name",
            label: "商品信息",
        },
        {
            width: "80",
            prop: "statusName",
            label: "状态",
            render: (h: any, scope: any) => {
                let showColor = '#606266';
                if (scope.row.status === 2) {   // 已上架
                    showColor = '#3bb94b';
                } else if (scope.row.status === 0) {   // 编辑中
                    showColor = '#2f9fca';
                } else if (scope.row.status === -1) {   // 审核不通过
                    showColor = '#f94549';
                } else if (scope.row.status === -2) {   // 强制下架
                    showColor = '#999999';
                }
                return h('span', {style: {color: showColor}}, scope.row.statusName || '-');
            },
        },
        {
            width: "150",
            prop: "monthlyPrice",
            label: "销售价格（元）",
            formatter(row: any, column: any, cellValue: any, index: any) {
                const arr: any = [];
                if (row.monthlyPrice) {
                    arr.push(row.monthlyPrice);
                }
                if (row.yearlyPrice) {
                    arr.push(row.yearlyPrice);
                }
                if (row.permanentMarketPrice) {
                    arr.push(row.permanentMarketPrice);
                }
                return arr.join('/');
            },
        },
        {
            width: "120",
            prop: "unit",
            label: "销售单位",
            formatter(row: any, column: any, cellValue: any, index: any) {
                const arr: any = [];
                if (row.monthlyPrice) {
                    arr.push('月');
                }
                if (row.yearlyPrice) {
                    arr.push('年');
                }
                if (row.permanentMarketPrice) {
                    arr.push('永久');
                }
                return arr.join('/');
            },
        },
        {
            width: "120",
            prop: "clicks",
            label: "点击量/销售",
            formatter(row: any, column: any, cellValue: any, index: any) {
                return `${row.clicks}/${row.sales}`;
            },
        },
        {
            width: "120",
            prop: "customerService",
            label: "专属客服",
        },
    ];
    private tableData = [];
    @Watch('searchParam.startDate')
    private startTimeChange(val: any) {
        if (this.searchParam.endDate && val > this.searchParam.endDate) {
            this.searchParam.endDate = val;
        }
        if (val) {
            this.endOptions.disabledDate = (cur: any) => {
                return cur < new Date(val.substr(0, 10) + ' 00:00:00');
            };
        } else {
            this.endOptions.disabledDate = (cur: any) => {
                return false;
            };
        }
    }
    @Watch('searchParam.endDate')
    private endTimeChange(val: any) {
        if (this.searchParam.startDate && val < this.searchParam.startDate) {
            this.searchParam.startDate = val;
        }
        if (val) {
            this.startOptions.disabledDate = (cur: any) => {
                return cur > new Date(val);
            };
        } else {
            this.startOptions.disabledDate = (cur: any) => {
                return false;
            };
        }
    }

    private mounted() {
        this.$nextTick(() => {
            this.getDataList();
        });
    }
    private handleSizeChange(val: any) {// 切换页数
        this.searchParam.pageNo = 1;
        this.searchParam.pageSize = val;
        this.getDataList();
    }
    private handleCurrentChange(val: any) {// 切换页码
        this.searchParam.pageNo = val;
        this.getDataList();
    }
    private getDataList() {
        const formRef: any = this.$refs.searchForm;
        formRef.validate((valid: boolean) => {
            if (valid) {
                const searchParam: any = Object.assign({}, this.searchParam);
                this.$httpService.getData(searchParam, '/apiProxy/api/frontend/mall/goods/saas')
                .then((res: any) => {
                    if (res && res.total > 0) {
                        this.tableData = res.list;
                        this.total = res.total;
                    } else {
                        this.tableData = [];
                        this.total = 0;
                    }
                });
            }
        });
    }
    private showHide() {// 查询表单收起与展开
        this.showHideFlag = !this.showHideFlag;
    }
    private onSearch() {
        this.searchParam.pageNo = 1;
        this.getDataList();
    }
    private resetForm(formName: any) {
        this.searchParam.startDate = '';
        this.searchParam.endDate = '';
        this.searchParam.pageNo = 1;
        this.searchParam.pageSize = 10;
        const formRef: any = this.$refs[formName];
        formRef.resetFields();
        this.$nextTick(() => {
            this.getDataList();
        });
    }
    private handleClick(row: any) {
        console.log(row);
    }
    private editSaas(row: any, page: string, type: string) {
        this.saasId = row.id;
        this.addPageType = type;
        this.activePage = page;
    }
    private closeAddSaas(num: number) {
        this.activePage = 'SAASLIST';
        if (num === 3) {
            this.getDataList();
        }
    }
    private handleCommand(com: string, row: any) {
        console.log(com);
        if (com === '1') {  // 购买记录
            this.saasId = row.id;
            this.activePage = 'SALELOG';
        } else if (com === '2') {   // 下架
            this.handleLower(row);
        } else if (com === '3') {   // 客服管理
            this.handleAddServe(row);
        } else if (com === '4') {   // 上架
            this.handleUpper(row);
        } else if (com === '5') {   // 删除
            this.handleDelete(row);
        }
    }
    // 添加专属客服
    private handleAddServe(row: any) {
        this.currentRow = row;
        this.addServiceFlag = true;
    }
    private addServiceHandleClose(num: number) {
        this.addServiceFlag = false;
        if (num === 3) {
            this.getDataList();
        }
    }
    // 删除
    private handleDelete(row: any) {
        this.$confirm(`确定要删除商品‘${row.name}’吗？`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }).then(() => {
            // 确定
            this.$httpService.deleteData({}, `/apiProxy/api/frontend/mall/goods/saas/${row.id}`)
                .then((res: any) => {
                    this.$message({
                        message: '删除成功!',
                        type: 'success',
                    });
                    this.onSearch();
                });
        }).catch(() => {
            // 取消
        });
    }
    // 上架
    private handleUpper(row: any) {
        let status = 0;
        if (row.status === 0) {
            status = 1;
        } else if (row.status === 2) {
            status = 0;
        }
        this.$confirm(`确定要上架商品‘${row.name}’吗？`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }).then(() => {
            // 确定
            this.$httpService.postData({
                status,
            }, `/apiProxy/api/frontend/mall/goods/saas/${row.id}/status`)
                .then((res: any) => {
                    this.$message({
                        message: `上架成功!`,
                        type: 'success',
                    });
                    this.onSearch();
                });
        }).catch(() => {
            // 取消
        });
    }
    // 下架
    private handleLower(row: any) {
        let status = 0;
        if (row.status === 0) {
            status = 1;
        } else if (row.status === 2) {
            status = 0;
        }
        this.$confirm(`商品下架后发布需进行重新审核上架，是否确认下架该产品？`, `提示`, {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }).then(() => {
            // 确定
            this.$httpService.postData({
                status,
            }, `/apiProxy/api/frontend/mall/goods/saas/${row.id}/status`)
                .then((res: any) => {
                    this.$message({
                        message: `下架成功!`,
                        type: 'success',
                    });
                    this.onSearch();
                });
        }).catch(() => {
            // 取消
        });
    }
}
